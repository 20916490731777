@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&family=Montserrat:wght@400;500&display=swap'); */

/* html { overflow-y: scroll; } */



.raleway-400 {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.raleway-500 {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.raleway-600 {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.raleway-700 {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  font-weight: 300;
  src: url('./assets/fonts/Manrope-Light.ttf') format('truetype');  
} 
 @font-face {
  font-family: 'Manrope';
  font-weight: 400;
  src: url('./assets/fonts/Manrope-Regular.ttf') format('truetype');
} 
@font-face {
  font-family: 'Manrope';
  font-weight: 500;
  src: url('./assets/fonts/Manrope-Medium.ttf') format('truetype');
} 
@font-face {
  font-family: 'Manrope';
  font-weight: 600;
  src: url('./assets/fonts/Manrope-SemiBold.ttf') format('truetype');
} 
@font-face {
  font-family: 'Manrope';
  font-weight: 700;
  src: url('./assets/fonts/Manrope-Bold.ttf') format('truetype'); 
} 
@font-face {
  font-family: 'Manrope';
  font-weight: 800;
  src: url('./assets/fonts/Manrope-ExtraBold.ttf') format('truetype');
} 


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Manrope', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-scroll {
  overflow-y: hidden !important; /* Hide scrollbar when modal is open */
}


.custom-input-landing {
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.7); 
  background-color: transparent; /* Modify this as needed */
  box-shadow: inset 0 0 20px 20px transparent;
  transition: background-color 5000s ease-in-out 0s;
}

.slide { 
    z-index: 1 !important;
}